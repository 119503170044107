/**
 * Do not edit directly
 * Generated on Wed, 04 Dec 2024 15:36:49 GMT
 */

export const ColorWhite = "#ffffff";
export const ColorGrayLightest = "#f4f5f6";
export const ColorGrayLighter = "#e8e9ec";
export const ColorGrayLight = "#a5aeb4";
export const ColorGray = "#62737a";
export const ColorGrayDark = "#2c384b";
export const ColorGrayDarker = "#1f2b3f";
export const ColorGrayDarkest = "#141f33";
export const ColorBlack = "#000000";
export const ColorBlueLightest = "#ebf6ff";
export const ColorBlueLighter = "#c8e8fa";
export const ColorBlueLight = "#9ecae1";
export const ColorBlue = "#2666f1";
export const ColorBlueDark = "#26227c";
export const ColorBlueDarker = "#141155";
export const ColorBlueDarkest = "#010125";
export const ColorTealLightest = "#e2fcff";
export const ColorTealLighter = "#abebe9";
export const ColorTealLight = "#32c9c5";
export const ColorTeal = "#07a49f";
export const ColorTealDark = "#32827e";
export const ColorTealDarker = "#084D4A";
export const ColorTealDarkest = "#003936";
export const ColorGreenLightest = "#e9fede";
export const ColorGreenLighter = "#c5efbb";
export const ColorGreenLight = "#8ccc87";
export const ColorGreen = "#4ba74f";
export const ColorGreenDark = "#38773f";
export const ColorGreenDarker = "#1a4621";
export const ColorGreenDarkest = "#0b3622";
export const ColorYellowLightest = "#fff0d6";
export const ColorYellowLighter = "#ffd8a4";
export const ColorYellowLight = "#f3b549";
export const ColorYellow = "#d97919";
export const ColorYellowDark = "#8e6800";
export const ColorYellowDarker = "#604300";
export const ColorYellowDarkest = "#3a2b00";
export const ColorRedLightest = "#feebeb";
export const ColorRedLighter = "#fdcbcf";
export const ColorRedLight = "#f4a1aa";
export const ColorRed = "#e8465e";
export const ColorRedDark = "#d04257";
export const ColorRedDarker = "#b4232d";
export const ColorRedDarkest = "#4b111b";
export const ColorOrangeLightest = "#feefeb";
export const ColorOrangeLighter = "#ffbfaf";
export const ColorOrangeLight = "#fa957d";
export const ColorOrange = "#e4653c";
export const ColorOrangeDark = "#b3521e";
export const ColorOrangeDarker = "#a73d07";
export const ColorOrangeDarkest = "#6b2500";
export const ColorPurpleLightest = "#f3ebfe";
export const ColorPurpleLighter = "#d1cbfd";
export const ColorPurpleLight = "#a9a1f4";
export const ColorPurple = "#7a63c7";
export const ColorPurpleDark = "#5f4eaa";
export const ColorPurpleDarker = "#413673";
export const ColorPurpleDarkest = "#010125";
export const ColorPinkLightest = "#feebf4";
export const ColorPinkLighter = "#fdcbe1";
export const ColorPinkLight = "#f4a1b5";
export const ColorPink = "#dd4889";
export const ColorPinkDark = "#ce3f81";
export const ColorPinkDarker = "#b42367";
export const ColorPinkDarkest = "#4b112c";
export const ColorChart1 = "#2666f1";
export const ColorChart2 = "#07a49f";
export const ColorChart3 = "#26227c";
export const ColorChart4 = "#e4653c";
export const ColorChart5 = "#dd4889";
export const ColorChart6 = "#5f4eaa";
export const ColorChart7 = "#084D4A";
export const ColorChart8 = "#604300";
export const ColorHeatWarm1 = "#4ba74f";
export const ColorHeatWarm2 = "#6fb457";
export const ColorHeatWarm3 = "#8ec262";
export const ColorHeatWarm4 = "#accf6e";
export const ColorHeatWarm5 = "#c8dc7d";
export const ColorHeatWarm6 = "#e4e98d";
export const ColorHeatWarm7 = "#ffdc83";
export const ColorHeatWarm8 = "#ffc16d";
export const ColorHeatWarm9 = "#fea45f";
export const ColorHeatWarm10 = "#fa8759";
export const ColorHeatWarm11 = "#f36759";
export const ColorHeatWarm12 = "#e8465e";
export const ColorHeatCool1 = "#ccf1ff";
export const ColorHeatCool2 = "#ace9ff";
export const ColorHeatCool3 = "#92d6f4";
export const ColorHeatCool4 = "#78c3ea";
export const ColorHeatCool5 = "#5faedf";
export const ColorHeatCool6 = "#4a9ad3";
export const ColorHeatCool7 = "#3789c9";
export const ColorHeatCool8 = "#2775bd";
export const ColorHeatCool9 = "#1d62b0";
export const ColorHeatCool10 = "#1b4ea1";
export const ColorScaleCool1 = "#4a9ad3";
export const ColorScaleCool2 = "#3789c9";
export const ColorScaleCool3 = "#1d62b0";
export const ColorScaleCool4 = "#1b4ea1";
export const ColorScaleWarm1 = "#4ba74f";
export const ColorScaleWarm2 = "#d97919";
export const ColorScaleWarm3 = "#e4653c";
export const ColorScaleWarm4 = "#d04257";
export const ColorStatusPositiveDark = "#38773f";
export const ColorStatusPositiveLight = "#e9fede";
export const ColorStatusPositiveLightForeground = "#38773f";
export const ColorStatusWarningDark = "#f3b549";
export const ColorStatusWarningDarkForeground = "#604300";
export const ColorStatusWarningNeutral = "#f3b549";
export const ColorStatusWarningLight = "#fff0d6";
export const ColorStatusWarningLightForeground = "#604300";
export const ColorStatusCriticalDark = "#b3521e";
export const ColorStatusCriticalLight = "#feefeb";
export const ColorStatusCriticalLightForeground = "#a73d07";
export const ColorStatusNegativeDark = "#d04257";
export const ColorStatusNegativeLight = "#feebeb";
export const ColorStatusNegativeLightForeground = "#b4232d";
export const ColorStatusSubtleDark = "#62737a";
export const ColorStatusSubtleLight = "#e8e9ec";
export const ColorStatusSubtleLightForeground = "#2c384b";
export const ColorStatusNeutralDark = "#2666f1";
export const ColorStatusNeutralLight = "#ebf6ff";
export const ColorStatusNeutralLightForeground = "#26227c";
export const ColorStatusHighlight1Dark = "#32827e";
export const ColorStatusHighlight1Light = "#e2fcff";
export const ColorStatusHighlight1LightForeground = "#084D4A";
export const ColorStatusHighlight2Dark = "#ce3f81";
export const ColorStatusHighlight2Light = "#feebf4";
export const ColorStatusHighlight3Dark = "#7a63c7";
export const ColorStatusHighlight3Light = "#f3ebfe";
export const ColorBorderSeparator = "#e8e9ec";
export const ColorBorderInteractiveDefault = "#62737a";
export const ColorBorderInteractiveError = "#e8465e";
export const ColorBorderInteractiveHover = "#2666f1";
export const ColorBorderInteractiveActive = "#2666f1";
export const ColorBorderInteractiveSelected = "#2666f1";
export const ColorBorderInteractiveDisabled = "#e8e9ec";
export const ColorBackgroundMainPage = "#e7e7e7";
export const ColorBackgroundInteractiveDefault = "#ffffff";
export const ColorBackgroundInteractiveHover = "#f4f5f6";
export const ColorBackgroundInteractiveActive = "#c8e8fa";
export const ColorBackgroundInteractiveSelected = "#ebf6ff";
export const ColorBackgroundInteractiveDisabled = "#f4f5f6";
export const ColorBackgroundStaticPrimary = "#ffffff";
export const ColorBackgroundStaticSecondary = "#f4f5f6";
export const ColorToastBackgroundWarning = "#f3b549";
export const ColorToastColorWarning = "#3a2b00";
export const ColorToastForegroundWarning = "#ffffff";
export const ColorButtonCtaSecondaryNotActive = "#32827e";
export const ColorButtonCtaSecondaryActive = "#084D4A";
export const ColorButtonSecondaryNotActive = "#26227c";
export const ColorButtonSecondaryNotActiveHoverBorder = "#084D4A";
export const ColorButtonSecondaryActive = "#141155";
export const ColorTypographyToneSubtle = "#62737a";
export const ColorTypographyTonePositive = "#38773f";
export const ColorTypographyToneNegative = "#d04257";
export const ColorTypographyToneWarning = "#b3521e";
export const ColorTextInteractiveDarkDefault = "#1f2b3f";
export const ColorTextInteractiveLightDefault = "#ffffff";
export const ColorTextInteractiveDisabled = "#62737a";
export const ColorTextStaticHeader = "#2c384b";
export const ColorTextStaticBody = "#1f2b3f";
export const ColorNavigationBackground = "#000026";
export const ColorNavigationHover = "#39394d";
export const ColorNavigationActive = "#19193b";
export const ColorSearchNavigationTextDefault = "#ffffff";
export const ColorSearchNavigationBackgroundDefault = "#000026";
export const ColorSearchNavigationHoverOutline = "transparent";
export const ColorSearchNavigationActiveOutline = "transparent";
export const ColorSearchNavigationResultsSectionBorder = "#39394d";
export const ColorSearchNavigationResultsItemHover = "#39394d";
export const ColorSearchNavigationResultsItemActive = "#19193b";
export const ColorButtonSecondaryNotActiveHover = "#1f2b3f";
export const FontFamilyDefault = "'Roboto', arial, sans-serif";
export const FontFamilyAlternative = "'Roboto', arial, sans-serif";
export const FontFamilyCode = "'Roboto Mono', courier, sans-serif";
export const FontFamilyHeading = "'Gilroy', arial, sans-serif";
export const FontFamilyDisplay = "'Gilroy', arial, sans-serif";
export const FontSizeXxSmall = "0.625rem";
export const FontSizeXSmall = "0.75rem";
export const FontSizeSmall = "0.875rem"; // body
export const FontSizeMedium = "1rem";
export const FontSizeLarge = "1.125rem";
export const FontSizeXLarge = "1.375rem";
export const FontSizeXxLarge = "1.6875rem";
export const FontSizeXxxLarge = "2rem";
export const FontSizeInteractiveDefault = "0.875rem";
export const FontWeightNormal = 400;
export const FontWeightMedium = 500;
export const FontWeightBold = 700;
export const FontWeightInteractiveDefault = 400;
export const FontWeightInteractiveSelected = 500;
export const LineHeightReset = "1";
export const LineHeightXSmall = "1.1"; // title/url cell
export const LineHeightSmall = "1.3"; // text
export const LineHeightMedium = "1.4";
export const LineHeightLarge = "1.5";
export const LineHeightXLarge = "1.6";
export const SizeButtonSmall = "1.375rem";
export const SizeButtonDefault = "2rem";
export const SizeButtonLarge = "2.5rem";
export const SizeIconSmall = "0.75rem";
export const SizeIconMedium = "1rem";
export const SizeIconDefault = "1.25rem";
export const SizeIconResponsive = "1.25em";
export const SizeIllustrationSmall = "6rem";
export const SizeIllustrationMedium = "8rem";
export const SizeIllustrationDefault = "12rem";
export const SizePillSmall = "1.25rem";
export const SizePillDefault = "1.375rem";
export const SizePillLarge = "1.5rem";
export const SizeShadowFocusInner = "0 0 0 0.125rem";
export const SizeShadowFocusOuter = "0 0 0 0.25rem";
export const SizeShadowHover = "0 0 0 0.1875rem";
export const SpaceXxSmall = "0.25rem";
export const SpaceXSmall = "0.5rem";
export const SpaceSmall = "0.75rem";
export const SpaceMedium = "1rem";
export const SpaceLarge = "1.25rem";
export const SpaceXLarge = "1.5rem";
export const SpaceXxLarge = "2rem";
export const SpaceXxxLarge = "2.5rem";
export const BorderRadiusSmall = "0.1875rem";
export const BorderRadiusMedium = "0.3125rem";
export const BorderRadiusRounded = "125rem";
export const OpacityShadowHover = "0.2";
export const ShadowLayerLow = "0 0.125rem 0.25rem 0 rgba(31, 43, 63, 0.08), 0 0.0625rem 0.125rem 0 rgba(31, 43, 63, 0.2)"; // rgba value equals color.gray--darker with 0.08/0.2 opacity
export const ShadowLayerMedium = "0 0.25rem 0.5rem 0 rgba(31, 43, 63, 0.12), 0 0.125rem 0.25rem 0 rgba(31, 43, 63, 0.08)"; // rgba value equals color.gray--darker with 0.12/0.08 opacity
export const ShadowLayerHigh = "0 0.5rem 0.75rem 0 rgba(31, 43, 63, 0.16), 0 0.25rem 0.375rem 0 rgba(31, 43, 63, 0.04)"; // rgba value equals color.gray--darker with 0.16/0.04 opacity
export const ShadowFocusBase = "0 0 0 0.125rem #ffffff";
export const ShadowFocusDefault = "0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #2666f1";
export const ShadowHoverDefault = "0 0 0 0.1875rem rgba(27, 78, 161, 0.2)"; // rgba value equals color.teal with opacity.shadow--hover
export const ShadowHoverError = "0 0 0 0.1875rem rgba(232, 70, 94, 0.2)"; // rgba value equals color.red with opacity.shadow--hover
export const ShadowHoverLeft = "inset 0.1875rem 0 0 0 #a5aeb4";
export const ShadowSelectedBottom = "inset 0 -0.1875rem 0 0 #2666f1";
export const ShadowSelectedLeft = "inset 0.1875rem 0 #2666f1";
export const LayoutSmall = "36rem";
export const LayoutMedium = "48rem";
export const LayoutLarge = "62rem";
export const LayoutXLarge = "75rem";
export const LayoutXxLarge = "100rem";
export const AnimationFast = "0.1s";
export const AnimationMedium = "0.2s";
export const AnimationSlow = "0.3s";
